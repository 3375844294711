<template>
  <v-container
    id="services"
    fluid
    tag="section"
  >
    <base-material-card
      title="Liste des Tâches"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              Nom
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="task in tasks"
            :key="task.id"
          >
            <td>{{ task.id }}</td>
            <td>{{ task.name }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </base-material-card>
  </v-container>
</template>

<script>
  import gql from 'graphql-tag'

  export default {
    name: 'Tasks',
    data () {
      return {
        tasks: [],
      }
    },
    apollo: {
      tasks: {
        query: gql`
          query {
            tasks (sort: ["sort", "name"]) {
              id
              name
            }
          }
        `,
        error (error) {
          const code = error.networkError?.statusCode
          if (code === 400 || code === 401) {
            localStorage.removeItem('jwt')
            this.$router.push({ name: 'Login' })
          }
        },
      },
    },
  }
</script>

<style scoped>

</style>
